import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import BenefitsSection from "../components/static-sections/BenefitsSection"
import BenefitsChart from "../components/static-sections/BenefitsChart"
import BenefitsMedical from "../components/static-sections/BenefitsMedical"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import TwoImageSection from "../components/dynamic-sections/TwoImageSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import ThreeBoxSection from "../components/dynamic-sections/ThreeBoxSection"
import GoogleMapSection from "../components/dynamic-sections/GoogleMapSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import KeynoteSection from "../components/dynamic-sections/KeynoteSection"
import HeroMiddleSection from "../components/dynamic-sections/HeroMiddleSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import ApartmentIcon from "../../assets/apartment-icon.svg"
import ValuesIcon from "../../assets/values.svg"
import ValuesNorhartIcon from "../../assets/value-norhart-logo.svg"
import BenefitsHealthPlan from "../../assets/benefits-health-plan.svg"
import VideoIcon from "../../assets/video-icon.svg"
import StarReviewIcon from "../../assets/five-star-rating.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const BenefitsPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/benefits/",
          name: "Benefits | Norhart",
          image: `${config.siteMetadata.siteUrl}/benefits/norhart-benefits-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Benefits | Norhart"
      description="Norhart offers best-in-class benefits for our employees and their families. We invest in our employees to help them achieve greatness!"
      keywords="norhart, benefits, free benefits, employee benefits, health care, dental, vision, legal, maternity, paternity, childcare, forest lake, blaine, oakdale, minneapolis"
      imageTwitter={`${config.siteMetadata.siteUrl}/benefits/norhart-benefits-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/benefits/norhart-benefits-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Benefits"
        subtitle="We Take Care Of Our People"
        imageTitle="Norhart Benefits"
        image="/benefits/norhart-benefits-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Norhart Benefits"
        tagLine="We invest in our people to help them achieve greatness"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Philosophy"
        title="Flexible Work Culture"
        subTitle="Our philosophy is to spend time in ways that make the best sense for you and your team. That means having a set schedule for some, and for others, it means getting stuff done on their own time. Finding a good fit for everyone is our top priority!"
        image="/benefits/norhart-you-are-our-most-valuable-asset.png"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <KeynoteSection
        backgroundColorOne="#1e4c80"
        backgroundColorTwo="#1e4c80"
        icon={VideoIcon}
        iconTitle="Norhart Keynote"
        title="Benefits"
        subtitle="Watch Norhart's CEO, Mike Kaeding, announce a range of new employee benefits during his November 29th, 2021 Keynote presentation to the company."
        colorPalette={colorPalette}
        posterFrame="/benefits/benefits-keynote-posterframe.png"
        keynoteUrl="/benefits/video/norhart-mike-kaeding-11-29-2021-benefits-keynote-HD-720.mp4"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Free Benefits"
        title="We Offer Lots Of Free Benefits!!"
        subTitle="Health Care*, Vision, Dental, Legal, Maternity, Paternity, Paid Miltary Training Days, Unlimited PTO, and MORE!"
        image="/benefits/norhart-free-benefits-anvil-text.gif"
        imageAlt="Norhart Support Team"
        subNote="* Bronze Health Plan Only."
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroMiddleSection
        imageTitle="Norhart Free Benefits"
        image="/benefits/norhart-free-benefits.png"
        colorPalette={colorPalette}
      />

      <BenefitsMedical />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Benefits"
        title="Healthcare Sharing Plans"
        subTitle="If you and your spouse prefer to be on a healthcare sharing plan? Norhart will help cover the cost of your health care premiums up to $500 for a comparable Gold or Silver plan."
        image="/benefits/norhart-benefits-health-care-sharing-plans.png"
        imageAlt="Norhart Unlimited PTO"
        textColor="#FFFFFF"
        asoEffect="fade-in"
      />

      <BenefitsChart />

      <HeroBottomSection
        backgroundColorOne="#EFEFEF"
        backgroundColorTwo="#EFEFEF"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Employees Say It Best"
        starRating="yes"
        subTitle={`"The company is passionate about growth. As an employee, I am constantly learning new things. I step out and do things I've never done before because I'm not afraid to fail. My failures are seen as growth and I'm not punished for trying." - Norhart Employee`}
        image="/support/norhart-employee-reviews-1.png"
        imageAlt="Norhart Employee Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Time Off"
        title="Unlimited PTO"
        subTitle="Norhart offers unlimited personal time off! Go ahead and take time off as needed as long as it does not interfere with your ability to complete your work. Just another fantastic Norhart benefit for our employees!"
        image="/benefits/norhart-benefits-unlimited-pto.png"
        imageAlt="Norhart Unlimited PTO"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="Norhart Benefits"
        badgeTitle="Benefits"
        title="Industry Game Changer"
        subtitle="If you or your spouse is on another health plan (Example: Humana, Cigna or aetna). Norhart will pay up to $500 of the cost of your plan. #Wow"
        imageTitleSubtitle="Medical Insuarnce Companies"
        imageSubtitle="/benefits/thrid-party-medical-insurance-companies.png"
        imageTitle="Norhart Game Changer Benefits"
        image="/benefits/game-changer-benefits.png"
        colorPalette={colorPalette}
        colSizeOne={5}
        colSizeTwo={7}
        bottomMargin={0}
        asoEffect="fade"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default BenefitsPage
