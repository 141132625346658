import React from "react"

/** svg */
import HeartIcon from "../../../assets/heart-icon.svg"
import PdfIcon from "../../../assets/pdf-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** const */
const BenefitsChart: React.FC = () => {
  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{
        background: "linear-gradient(#cfe9f9, #cfe9f9)",
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3">
        <div>
          <div className="text-center pb-1 text-center">
            <HeartIcon />
          </div>
        </div>

        <div className="contentHeroSpace hidden-xs"></div>
        <h2 className="contentHeroTitle" style={{ color: "#365c70" }}>
          The Low Down On Our Benefits
        </h2>
        <h2 className="contentHeroSubTitle mb-5" style={{ color: "#365c70", opacity: "0.7" }}>
          Norhart provides incredible benefits for our United States employees and their families. We believe that our
          people are our number one asset!
        </h2>

        {/* LARGE AND X-LARGE VIEW */}
        <div className="d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="col-4 chartContent">
                <strong>Benefit</strong>
              </div>
              <div className="col-6 chartContent">
                <strong>Details</strong>
              </div>
              <div className="col-1 chartContent-center">
                <strong>PDF</strong>
              </div>
              <div className="col-1 chartContent text-end">
                <strong>Cost</strong>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Medical</div>
              <div className="col-6 chartContent">Bronze Plan is free.</div>

              <div className="col-1 chartContent-center">
                <a href="/benefits/pdf/norhart-bronze-medical-plan.pdf" target="_blank">
                  <PdfIcon />
                </a>
              </div>

              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Dental*</div>
              <div className="col-6 chartContent">Individual $Free, Individual+ $25, Family $50</div>

              <div className="col-1 chartContent-center">
                <a href="/benefits/pdf/norhart-dental-plan.pdf" target="_blank">
                  <PdfIcon />
                </a>
              </div>

              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Vision*</div>
              <div className="col-6 chartContent">Individual $Free, Individual+ $3, Family $6</div>

              <div className="col-1 chartContent-center">
                <a href="/benefits/pdf/norhart-vision-plan.pdf" target="_blank">
                  <PdfIcon />
                </a>
              </div>

              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Short-Term Disability</div>
              <div className="col-6 chartContent">60% of salary</div>
              <div className="col-1 chartContent-center">
                <a href="/benefits/pdf/norhart-short-term-disability-plan.pdf" target="_blank">
                  <PdfIcon />
                </a>
              </div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Long Term Disability</div>
              <div className="col-6 chartContent">60% of salary</div>
              <div className="col-1 chartContent-center">
                <a href="/benefits/pdf/norhart-long-term-disability-plan.pdf" target="_blank">
                  <PdfIcon />
                </a>
              </div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Life Insurance**</div>
              <div className="col-6 chartContent">1 year's salary capped at $150k</div>
              <div className="col-1 chartContent-center">
                <a href="/benefits/pdf/norhart-life-insurance-AD-and-D-plan.pdf" target="_blank">
                  <PdfIcon />
                </a>
              </div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">AD&D**</div>
              <div className="col-6 chartContent">1 year's salary capped at $150k</div>
              <div className="col-1 chartContent-center">
                <a href="/benefits/pdf/norhart-life-insurance-AD-and-D-plan.pdf" target="_blank">
                  <PdfIcon />
                </a>
              </div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Employee Assistance Program</div>
              <div className="col-6 chartContent">Legal, financial and help during tough times.</div>
              <div className="col-1 chartContent-center">
                <a href="/benefits/pdf/norhart-eap-plan.pdf" target="_blank">
                  <PdfIcon />
                </a>
              </div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Maternity</div>
              <div className="col-6 chartContent">8 weeks off</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Paternity</div>
              <div className="col-6 chartContent">2 weeks off</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Military Friendly</div>
              <div className="col-6 chartContent">Compensation for "Reserve Drill" days</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Unlimited PTO</div>
              <div className="col-6 chartContent">Paid personal time off</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Volunteer Time Off</div>
              <div className="col-6 chartContent">16hrs a year paid time off</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">401K</div>
              <div className="col-6 chartContent">Company match up to 4%</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Tool Reimbursement***</div>
              <div className="col-6 chartContent">$100 annual for necessary tools or replacement</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Boot Reimbursement***</div>
              <div className="col-6 chartContent">$100 annual for steel or composite toe boots</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Paid Holidays</div>
              <div className="col-6 chartContent">6 days off per year</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          {/* <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Rent Discount</div>
              <div className="col-6 chartContent">Terms and conditions apply</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div> */}

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Giving</div>
              <div className="col-6 chartContent">Employer dollar for dollar match</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-12 chartContent">
                * Individual plan is free. Cost is per paycheck.
                <br />
                ** May increase the benefit for an additional fee.
                <br />
                *** Construction employees only.
                <br />
                <br />
                <strong>Note:</strong> Not all benefits apply to international employees.
              </div>
            </div>
          </div>
        </div>

        {/* SMALL AND MEDIUM VIEW */}
        <div className="d-block d-lg-none">
          <div className="container">
            <div className="row">
              <div className="col-4 chartContent">
                <strong>Benefit</strong>
              </div>
              <div className="col-6 chartContent">
                <strong>Details</strong>
              </div>
              <div className="col-2 chartContent text-end">
                <strong>Cost</strong>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Medical</div>
              <div className="col-6 chartContent">Bronze Plan is free.</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Dental*</div>
              <div className="col-6 chartContent">Individual $Free, Individual+ $25, Family $50</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Vision*</div>
              <div className="col-6 chartContent">Individual $Free, Individual+ $3, Family $6</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Long Term Disability</div>
              <div className="col-6 chartContent">60% of salary</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Life Insurance**</div>
              <div className="col-6 chartContent">1 year's salary capped at $150k</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">AD&D**</div>
              <div className="col-6 chartContent">1 year's salary capped at $150k</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Employee Assistance Program</div>
              <div className="col-6 chartContent">Legal, financial and help during tough times.</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Maternity</div>
              <div className="col-6 chartContent">8 weeks off</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Paternity</div>
              <div className="col-6 chartContent">2 weeks off</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Military Friendly</div>
              <div className="col-6 chartContent">Compensation for "Reserve Drill" days</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Unlimited PTO</div>
              <div className="col-6 chartContent">Paid personal time off</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Volunteer Time Off</div>
              <div className="col-6 chartContent">16hrs a year paid time off</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">401K</div>
              <div className="col-6 chartContent">Company match up to 4%</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Tools***</div>
              <div className="col-6 chartContent">$100 annual for necessary tools or replacement</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Boots***</div>
              <div className="col-6 chartContent">$100 annual for steel or composite toe boots</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Rent Discount</div>
              <div className="col-6 chartContent">Terms and conditions apply</div>
              <div className="col-1 chartContent-center"></div>
              <div className="col-1 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-4 chartContent">Giving</div>
              <div className="col-6 chartContent">Employer dollar for dollar match</div>
              <div className="col-2 chartContent text-end">
                <span
                  className="badge"
                  style={{
                    fontSize: "0.7rem",
                    backgroundColor: "#FFFFFF",
                    color: "#111111",
                  }}
                >
                  FREE
                </span>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row chartBorder">
              <div className="col-12 chartContent">
                * Individual plan is free. Cost is per paycheck.
                <br />
                <br />
                ** May increase the benefit for an additional fee.
                <br />
                <br />
                *** Construction employees only.
                <br />
                <br />
                <strong>Note:</strong> Not all benefits apply to international employees.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/** export */
export default BenefitsChart
